export const initialAbility = [
    {
        action: 'read',
        subject: 'Auth',
    },
]

export const abilities = {
    administrator: [
        {
            action: 'read',
            subject: 'Auth',
        },
    ],
}

export const _ = undefined
